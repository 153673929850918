<template>
<Breadcrumb page-title="OpenID" page-section="Settings"> </Breadcrumb>
  <section class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="box box-info">
                <div class="box-header with-border">
                    <i class="fa fa-sign-in"></i>
                    <h3 class="box-title text-right">OpenID Connect Configuration</h3>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="">
                        <div class="col-md-12 mbottom-15">
                            <p> To use OpenID with iService, you need to first register your entity and obtain a Client ID that can be entered into iService. </p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/ms-symbollockup_signin_dark.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input detail-g-borderbottom">
                                        <label class="col-md-12 control-label text-left group-input-label">Azure Client ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="group-bg group-input detail-g-bordertop">
                                        <label class="col-md-12 control-label text-left group-input-label">Azure Tenant ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/btn_okta_signin_dark_normal_web.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input detail-g-borderbottom">
                                        <label class="col-md-12 control-label text-left group-input-label">Okta Client ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="group-bg group-input detail-g-bordertop">
                                        <label class="col-md-12 control-label text-left group-input-label">Okta Domain</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/btn_google_signin_dark_normal_web.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input">
                                        <label class="col-md-12 control-label text-left group-input-label">Google Client ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <button type="button" class="btn btn-primary margin-r-5">Save</button>
                        </div>
                        <div class="col-10 mt-3">
                            <!--v-if-->
                        </div>
                        <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'


export default {
  name: 'List Settings',
   components: {
    Breadcrumb,
    Toggle,
    Multiselect,
    
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showListDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>